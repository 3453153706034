import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const OrdersAPI = createApi({
  reducerPath: "OrdersAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
  }),
  tagTypes: ['Orders'],
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: () => 'orders/allorders',
      providesTags: ['Orders'],
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `orders/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Orders' }],
    }),
  }),
});

export const { useGetOrdersQuery, useDeleteOrderMutation } = OrdersAPI;