import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const UsersAPI = createApi({
  reducerPath: "UsersAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
  }),
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => 'auth/users',
      providesTags: ['Users'],
    }),
    getUser: builder.query({
      query: () => 'auth',
      providesTags: ['Users'],
    }),
    updateUser: builder.mutation({
      query: (id, ...rest) => ({
        url: `auth/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: [{ type: 'Users' }],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `user/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Users' }],
    }),
  }),
});

export const { useGetUsersQuery, useGetUserQuery, useUpdateUserMutation, useDeleteUserMutation } = UsersAPI;