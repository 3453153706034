import { Provider } from "react-redux";
import { store } from "@/services/store";
import { SnackbarProvider, closeSnackbar } from "notistack";
import { SessionProvider, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { Inter } from "next/font/google";
import { Button } from "@material-tailwind/react";
import { AnimatePresence, motion } from "framer-motion";
import { NextUIProvider } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider, useTheme } from "next-themes";
import Loading from "@/custom/Loading";
import "@/styles/globals.css";

const inter = Inter({ subsets: ["latin"] });

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const router = useRouter();
  const { systemTheme } = useTheme();
  const currentTheme = (theme) =>
    theme === "system" ? systemTheme : theme === "dark" ? "dark" : "light";

  return (
    <SessionProvider session={session}>
      <Provider store={store}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          maxSnack={3}
          action={(snackbarId) => (
            <Button
              size='sm'
              variant='text'
              className='capitalize'
              onClick={() => closeSnackbar(snackbarId)}
            >
              Dismiss
            </Button>
          )}
        >
          <AnimatePresence
            mode='wait'
            initial={false}
            onExitComplete={() => window.scrollTo(0, 0)}
          >
            <motion.div
              key={router.route}
              initial='initial'
              animate='animate'
              exit='exit'
              transition={{ type: "easeInOut", duration: 0.45 }}
              variants={{
                initial: { opacity: 0, x: 0, y: 50 },
                animate: { opacity: 1, x: 0, y: 0 },
                exit: { opacity: 0, x: 0, y: -50 },
              }}
            >
              {Component.auth ? (
                <Auth adminOnly={Component.auth.adminOnly}>
                  <NextUIProvider>
                    <NextThemesProvider
                      attribute='class'
                      defaultTheme='system'
                      enableSystem={true}
                    >
                      <main
                        className={`${currentTheme} dark:bg-ox-dark-body ${inter.className}`}
                      >
                        <Component {...pageProps} />
                      </main>
                    </NextThemesProvider>
                  </NextUIProvider>
                </Auth>
              ) : (
                <NextUIProvider>
                  <NextThemesProvider
                    attribute='class'
                    defaultTheme='system'
                    enableSystem={true}
                  >
                    <main
                      className={`${currentTheme} dark:bg-ox-dark-body ${inter.className}`}
                    >
                      <Component {...pageProps} />
                    </main>
                  </NextThemesProvider>
                </NextUIProvider>
              )}
            </motion.div>
          </AnimatePresence>
        </SnackbarProvider>
      </Provider>
    </SessionProvider>
  );
}

function Auth({ children, adminOnly }) {
  const router = useRouter();
  const { status, data: session } = useSession({
    required: true,
    onUnauthenticated() {
      router.push("/unauthorized?message=login required");
    },
  });
  if (status === "loading") {
    return <Loading />;
  }
  if (adminOnly && !session.user.isAdmin) {
    router.push("/unauthorized?message=admin login required");
  }

  return children;
}

export async function getServerSideProps(context) {
  context.res.setHeader("X-Robots-Tag: noindex, nofollow", true);
  return {
    props: {},
  };
}

export default MyApp;
