import React from 'react';

const Loading = ({ isLoading }) => {
  if (!isLoading) {
    return null; // If there's no error, return nothing
  }
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="animate-spin rounded-full border-t-4 border-blue-500 border-solid h-12 w-12"></div>
      <div className="mt-2 text-gray-700">Loading...</div>
    </div>
  );
};

export default Loading;