import { createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import Cookies from 'js-cookie';

const initialState = {
  cartProducts: Cookies.get('cart') ? JSON.parse(Cookies.get('cart')) : [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
};

export const CartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart(state, action) {
      console.log('payload',action.payload)
      const product = state.cartProducts.findIndex(
        (item) => item.id === action.payload.id
      );
      if (product >= 0) {
        enqueueSnackbar(`${action.payload.item} material is already in your cart.`, {
          variant: 'default',
        });
      } else {
        const tempProduct = {
          item: action.payload.item,
          price: action.payload.price,
          id: action.payload.id,
          cartQuantity: 1,
        };
        state.cartProducts.push(tempProduct);
        enqueueSnackbar(`${action.payload.item} material has been added`, {
          variant: 'default'
        });
      }
      Cookies.set('cart', JSON.stringify(state.cartProducts));
    },
    purchaseAll(state, action) {
      state.cartProducts = [
        { item: 'Speaking', price: 150, id: 1, cartQuantity: 1 },
        { item: 'Writing', price: 100, id: 2, cartQuantity: 1 },
        { item: 'Reading', price: 250, id: 3, cartQuantity: 1 },
        { item: 'Listening', price: 50, id: 4, cartQuantity: 1 },
      ];
      state.cartTotalAmount === 550
        ? enqueueSnackbar(`${action.payload.item} material is already in your cart.`, {
          variant: 'default',
        })
        : enqueueSnackbar(`${action.payload.item} material has been added`, {
          variant: 'default'
        });
      Cookies.set('cart', JSON.stringify(state.cartProducts));
    },

    removeFromCart(state, action) {
      state?.cartProducts?.map((cartItem) => {
        if (cartItem?.id === action?.payload?.id) {
          const nextCartProducts = state?.cartProducts?.filter(
            (item) => item?.id !== cartItem?.id
          );
          enqueueSnackbar(`${action.payload.item} material has been removed from your cart`, {
            variant: 'default'
          });
          state.cartProducts = nextCartProducts;
        }
        Cookies.set('cart', JSON.stringify(state.cartProducts));
        return state;
      });
    },

    getTotals(state, action) {
      let { total, quantity } = state.cartProducts.reduce(
        (cartTotal, cartItem) => {
          const { price, cartQuantity } = cartItem;
          const itemTotal = price * cartQuantity;

          cartTotal.total += itemTotal;
          cartTotal.quantity += cartQuantity;

          return cartTotal;
        },
        {
          total: 0,
          quantity: 0,
        }
      );
      total = parseFloat(total.toFixed(2));
      state.cartTotalQuantity = quantity;
      state.cartTotalAmount = total;
    },
  },
});

export const { addToCart, purchaseAll, removeFromCart, getTotals } =
  CartSlice.actions;

export const cartReducer = CartSlice.reducer;
