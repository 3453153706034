import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"

import { sidebarReducer } from './sidebarSlice';
import { cartReducer } from './cart';
import { OrdersAPI } from './order'; // Fixed the import name
import { UsersAPI } from './user'; // Fixed the import name

export const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    cart: cartReducer,
    [OrdersAPI.reducerPath]: OrdersAPI.reducer, // Fixed the import name
    [UsersAPI.reducerPath]: UsersAPI.reducer, // Fixed the import name
  },
  middleware: (gDM) =>
    gDM().concat([
      OrdersAPI.middleware,
      UsersAPI.middleware
    ]),
});

setupListeners(store.dispatch);
